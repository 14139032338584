import { createContext } from "react";
import { IFullPermission } from "./PermissionsCard";
import { IConnectionInfo } from "../models/IConnectionInfo";
import { IDriverExtended } from "../../../bffmodels/IDriverExtended";
import { DynamicPropsStatus } from "./DynamicConnectionProperty";

export interface IConnectionContext {
  chameleonId: string;
  connectionId: string;
  connectionInfo: IConnectionInfo;
  connections: any[];
  currentlyCreatingReports: boolean;
  currentTab: string;
  driverInfo: IDriverExtended;
  driverType: string;
  dynamicPropsStatus: DynamicPropsStatus;
  formRef: React.RefObject<HTMLFormElement>;
  hasUserConnectionString: boolean;
  isCacheConnection: boolean;
  isInitialSetupPage: boolean;
  isOEMConnection: boolean;
  fromSpreadsheetsClient: boolean;
  loading: boolean;
  oauthProps: { [key: string]: string } | undefined;
  oauthButtonInvalid: boolean;
  overrideConnectionType: boolean;
  permissions: IFullPermission[];
  processingRequest: boolean;
  saveAndTestFailed: boolean;
  selectedFile: string;
  setChameleonId: (newState: string) => void;
  setConnectionId: (newState: string) => void;
  setConnectionInfo: (newState: IConnectionInfo) => void;
  setCurrentlyCreatingReports: (newState: boolean) => void;
  setCurrentTab: (newState: string) => void;
  setDriverInfo: (newState: IDriverExtended) => void;
  setDynamicPropsStatus: (newState: DynamicPropsStatus) => void;
  setHasUserConnectionString: (newState: boolean) => void;
  setLoading: (newState: boolean) => void;
  setOauthProps: (newState: { [key: string]: string }) => void;
  setOauthButtonInvalid: (newState: boolean) => void;
  setPermissions: (newState: IFullPermission[]) => void;
  setProcessingRequest: (newState: boolean) => void;
  setSaveAndTestFailed: (newState: boolean) => void;
  setSelectedFile: (newState: string) => void;
  setUnavailableNames: (names: string[]) => void;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  setUserDefinedProps: (newState: string[]) => void;
  setUsersList: (newState: any[]) => void;
  setWalletFilePresent: (newState: boolean) => void;
  unavailableNames: string[];
  unsavedChanges: boolean;
  updatePreventNavigation?: (
    preventNavigation: boolean,
    preventNavigationTitle: string,
    preventNavigationMessage: string,
  ) => void | undefined;
  userDefinedProps: string[];
  usersList: any[];
  walletFilePresent: boolean;
}

export const ConnectionContext = createContext<IConnectionContext>(null!);
